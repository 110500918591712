<template>
    <div id="washer">
        <div id="clock"></div>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            style="display: none;">
            <symbol id="wave">
                <path
                    d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z">
                </path>
                <path
                    d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z">
                </path>
                <path
                    d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z">
                </path>
                <path
                    d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z">
                </path>
            </symbol>
        </svg>

        <div id="door">
            <div id="inner-door">
                <div id="drum">
                </div>
                <div class="handle"></div>
                <div id="page" class="page">
                    <div class="percent">
                        <div class="temperature">
                        </div>
                        <div id="water" class="water">
                            <svg viewBox="0 0 560 20" class="water-wave water-wave-back">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#wave"></use>
                            </svg>
                            <svg viewBox="0 0 560 20" class="water-wave water-wave-front">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#wave"></use>
                            </svg>
                            <div class="water-inner">
                                <div class="bubble bubble1"></div>
                                <div class="bubble bubble2"></div>
                                <div class="bubble bubble3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    remainingTimeInMinute: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped>
@import url(https://fonts.googleapis.com/css?family=Shrikhand);

#washer {
    margin: 10px auto;
    width: 200px;
    text-align: center;
}

/* .centered {
    margin: 50px auto;
    text-align: center;
    font-family: 'Shrikhand', cursive;
} */

.laundry {
    font-family: 'Shrikhand', cursive;
    font-size: 56px;
    top: 6px;
    margin: 25px auto;
    text-align: center;
}

/* .gray {
    color: #999;
} */

*,
*:before,
*:after {
    box-sizing: border-box;
    outline: none;
}

p {
    font-family: 'Roboto', sans-serif;
    width: 50em;
    margin: 25px auto;
}

h3 {
    font-family: 'Roboto', sans-serif;
    width: 50em;
    margin: 25px auto;
    text-align: center;
}

a {
    text-decoration: none;
}

@keyframes circle {
    from {
        transform: rotate(0deg) rotate(-360deg);
    }

    to {
        transform: rotate(0deg) rotate(360deg);
    }
}

#door {
    background-image: -webkit-linear-gradient(top, #f4f1ee, #fff);
    background-image: linear-gradient(top, #f4f1ee, #fff);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, .3), inset 0px 4px 1px 1px white, inset 0px -3px 1px 1px rgba(204, 198, 197, .5);
    border-radius: 50%;
    height: 200px;
    width: 200px;
    position: relative;
    border: 2px solid #ccc;
}

#door #inner-door {
    background: #666;
    border-radius: 50%;
    height: 140px;
    width: 140px;
    position: relative;
    border: 2px solid #ccc;
    top: 28px;
    left: 28px;
}

#door #inner-door #drum {
    background: #fff;
    width: 120px;
    height: 120px;
    margin: 5px auto;
    -webkit-align-self: center;
    -moz-align-self: center;
    align-self: center;
    border-top: 15px solid #bbb;
    border-left: 15px solid #bbb;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #bbb;
    border-radius: 50%;
    box-shadow: 0 0 5px 1px #aaa;
    animation: circle 3s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -60px 0px 0px -60px;
}

.handle::before {
    content: '‹ ›';
    text-align: center;

    font-size: 24px;
    line-height: 26px;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    top: 50%;
    left: 105%;
    font-weight: 700;
    margin: -15px 0 0 -15px;
    background: #fff;
    -moz-box-shadow: 0px 1px 1px 0px #222, inset 0px 1px 1px 0px white;
    -webkit-box-shadow: 0px 1px 1px 0px #222, inset 0px 1px 1px 0px white;
    box-shadow: 0px 1px 1px 0px #222, inset 0px 1px 1px 0px white;
}

.arc {
    overflow: hidden;
    position: absolute;
    top: -1em;
    right: 50%;
    bottom: 50%;
    left: -1em;
    transform-origin: 100% 100%;
    transform: rotate(165deg) skewX(60deg);

}

.arc:before {
    box-sizing: border-box;
    display: block;
    border: solid 1.3em #777;
    width: 225%;
    height: 210%;
    border-radius: 50%;
    transform: skewX(-60deg);
    content: '';
}

.page {
    height: 115px;
    width: 115px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #ccc;
    /*#1e384c;*/
    border-radius: 50%;
    overflow: hidden;
}

.percent {
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: lighter;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.temperature {
    display: inline-block;
}

.temperature-num {
    display: inline-block;
    font-size: 1.5em;
}

.temperature-sign {
    display: inline-block;
    font-size: 1.5em;
}

.water {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 5;
    bottom: 0;
    left: 0;
    background: #32bafa;
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
}

.water-inner {
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    overflow: hidden;
}

.water-wave {
    width: 200%;
    position: absolute;
    bottom: 100%;
}

.water-wave-back {
    right: 0;
    fill: #aaa;
    /*#2c7fbe;*/
    -webkit-animation: wave-back 1.9s infinite linear;
    animation: wave-back 1.9s infinite linear;
}

.water-wave-front {
    left: 0;
    fill: #32bafa;
    margin-bottom: -1px;
    -webkit-animation: wave-front 1.2s infinite linear;
    animation: wave-front 1.2s infinite linear;
}

@-webkit-keyframes wave-front {
    100% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

@keyframes wave-front {
    100% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

@-webkit-keyframes wave-back {
    100% {
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0);
    }
}

@keyframes wave-back {
    100% {
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0);
    }
}
</style>
