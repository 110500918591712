<template>
    <!-- <button @click="showAlert">Hello world</button> -->
    {{ showAlert() }}
</template>
<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
export default {
  mounted () {
    this.showAlert()
  },
  methods: {
    showAlert () {
      Swal.fire({
        icon: 'success',
        title: 'เครื่องทำงานเสร็จแล้ว!!',
        // text: 'เครื่องทำงานเสร็จแล้ว !',
        denyButtonText: 'ok'
      })
    }
  }
}
</script>
