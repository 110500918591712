<template>
    <div>
        <div>
            <button type="button" class="previous mt-3 mx-3" @click="previous()">
                <img src="@/assets/img/left.png" alt="btn" class="btn-previous">
            </button>
        </div>
        <div class="promptpay" id="saveQR">

            <div class="mt-5 scan-qr">
                <img src="@/assets/img/thai_qr_payment.png" alt="" class="canvas-promptpay">
                <!-- <img src="https://cdn.ttgtmedia.com/rms/misc/qr_code_barcode.jpg" alt="" class="qr-promptpay"> -->
                <!-- <qrcode-vue v-if="QRcode" :value="QRcode" size="210" level="H" class="qr-promptpay mt-2"/> -->
                <QrcodeImage v-if="QRcode" :value="QRcode" class="qr-promptpay" :size="6" />

                <p class="amount">Amount : {{ this.$route.query.price }} Bath</p>
            </div>
            <!-- <p>Detected mobile operating system: {{ getMobileOperatingSystem() }}</p> -->
        </div>
        <div class="text-end button">
            <button type="button" class="btn save" @click="saveQRCode">บันทึกคิวอาร์โค้ด</button>
        </div>
        <div class="grid mt-4 px-4">
            <p class="text-start fw-bold px-2 pb-0">กดเพื่อเปิดหน้าหน้าแอปธนาคาร</p>
            <p class="text-start mb-3 captrue px-2">*กรุณาแคปหน้าจอ หรือบันทึกรูปภาพคิวอาร์โค้ดเพื่อนำไปแสกนจ่าย</p>
            <div class="item">
                <a href="scbeasy://">
                    <div class="content">
                        <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/scb.png" alt="scb"
                            class="mobile-bank">
                        <p class="bankname">SCB Easy</p>
                    </div>
                </a>
            </div>
            <div class="item">
                <a href="ktbnext://">
                    <div class="content">
                        <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/kungthai.png" alt="Krungthai"
                            class="mobile-bank">
                        <p class="bankname">Krungthai NEXT</p>
                    </div>
                </a>
            </div>
            <div class="item">
                <a href="https://www.kasikornbank.com/th/apply/kplus/pages/qr_download.aspx">
                    <div class="content">
                        <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/kbank.png" alt="kplus"
                            class="mobile-bank">
                        <p class="bankname">K PLUS</p>
                    </div>
                </a>
            </div>
            <div class="item">
                <a href="krungsri-kma://">
                    <div class="content">
                        <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/kungsri.png" alt="KMA"
                            class="mobile-bank">
                        <p class="bankname">Krungsri Mobile App (KMA)</p>
                    </div>
                </a>
            </div>
            <div class="item">
                <a href="bualuangmbanking://">
                    <div class="content">
                        <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/bangkok.png" alt="Bualuang"
                            class="mobile-bank">
                        <p class="bankname">Bualuang mBankking</p>
                    </div>
                </a>
            </div>
            <div class="item">
                <a href="TMBTOUCH://">
                    <div class="content">
                        <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/tmb.png" alt="ttb"
                            class="mobile-bank">
                        <p class="bankname">ttb touch</p>
                    </div>
                </a>
            </div>
        </div>

        <!-- <div class="mt-3 px-3">
            <p class="text-start mb-2 fw-bold">กดเพื่อเปิดแอปธนาคาร</p>
            <div class="bank-item">
                <div class="bank">
                    <a href="scbeasy://">
                        <div>
                            <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/scb.png" alt="scb"
                                class="img-bank">
                        </div>
                    </a>
                </div>
                <div class="bank">
                    <a href="ktbnext://">
                        <div>
                            <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/kungthai.png"
                                alt="Krungthai" class="img-bank">
                        </div>
                    </a>
                </div>
                <div class="bank">
                    <a href="krungsri-kma://">
                        <div>
                            <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/kungsri.png" alt="KMA"
                                class="img-bank">
                        </div>
                    </a>
                </div>
            </div>
            <div class="bank-item">
                <div class="bank">
                    <a href="bualuangmbanking://">
                        <div>
                            <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/bangkok.png" alt="Bualuang"
                                class="img-bank">
                        </div>
                    </a>
                </div>
                <div class="bank">
                    <a href="TMBTOUCH://">
                        <div>
                            <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/tmb.png" alt="ttb"
                                class="img-bank">
                        </div>
                    </a>
                </div>
                <div class="bank">
                    <a href="kbank-kplus://">
                        <div>
                            <img src="https://justwash.s3-ap-southeast-1.amazonaws.com/bank_logo/kbank.png" alt="kplus"
                                class="img-bank">
                        </div>
                    </a>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>

import { GenerateQR } from '@/service/api'
import html2canvas from 'html2canvas'
import QrcodeImage from 'vue-qrcode-image'

export default {
  data () {
    return {
      QRcode: '',
      price: ''
    }
  },
  components: {
    QrcodeImage
  },
  async mounted () {
    this.getQRcode()
  },
  methods: {
    // GenerateQRCodetoPayment
    async getQRcode () {
      try {
        const response = await GenerateQR(this.$route.query.washingMachineId, this.$route.query.mode, this.$route.query.userId, this.$route.query.price)
        console.log('API Response:', response)
        if (response.data.message.status === true) {
          this.QRcode = response.data.message.data.qrcode_data
        //   this.price = response.data.message.data.price
        } else {
          console.log('เกิดข้อผิดพลาด')
        }
      } catch (error) {
        console.error('API Error:', error)
      }
    },
    // SaveQR and Check ios android
    saveQRCode () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        alert('ระบบ iOS ไม่สามารถบันทึกรูปภาพได้ กรุณาแคปหน้าจอ หรือ กดค้างที่รูปภาพคิวอาร์โค้ดแล้วกดบันทึกรูปภาพ')
        return
      }
      const qrCode = document.getElementById('saveQR')
      html2canvas(qrCode).then(function (canvas) {
        const a = document.createElement('a')
        a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
        a.download = 'QRcode.jpg'
        a.click()
      })
    },
    // Check ios android
    getMobileOperatingSystem () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }
      if (/android/i.test(userAgent)) {
        return 'Android'
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }
      return 'unknown'
    },
    // Back to SelectMode
    previous () {
      this.$router.push({ path: '/modeView', query: { machineID: this.$route.query.washingMachineId, mode: this.$route.query.mode, userId: this.$route.query.userId } })
    }
  }
}
</script>
<style scoped>
@import "@/assets/css/payment-css.css";
.captrue{
    font-size: 12px;
    color: gray;
}
</style>
