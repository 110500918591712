<template>
  <div>
    <div class="text-end mt-2 p-3">
      <button class="close" type="button" @click="close"></button>
    </div>
    <div>
      <img src="@/assets/img/LINE_logo.svg.png" alt="image" class="image">
      <h1>เป็นเพื่อนกับเรา</h1>
      <div class="mt-4">
        <p class="text">รับสิทธิพิเศษ</p>
        <div class="d-flex justify-content-center align-items-center">
          <hr class="new1">
          <p>+</p>
          <hr class="new1">
        </div>
        <p class="text">โปรโมชั่นก่อนใคร !</p>
      </div>
    </div>
    <div class="mb-2">
      <button type="button" class="add-friend" @click="addFriend()">เพิ่มเพื่อนเลย</button>
    </div>
    <div>
      <button type="button" class="not-add" @click="close()">ยังก่อน</button>
    </div>
  </div>
    <!-- <div>
      <button type="button" class="not-add" @click="logOut()">ออก</button>
    </div> -->
</template>
<script>
import liff from '@line/liff'
export default {
  data () {
    return {
    }
  },
  mounted () {
    this.initializeLIFF()
  },
  methods: {
    // async initializeLIFF () {
    //   try {
    //     await liff.init({ liffId: '2000943917-2wg94M79' })
    //       .then(() => {
    //         const idToken = liff.getAccessToken()
    //         console.log('idToken', idToken)
    //         if (liff.isLoggedIn()) {
    //           await this.getUserProfile(); // Wait for user profile to be fetched and set UserID
    //           console.log('UserID after getUserProfile:', this.userId); // Log to check if UserID is set
    //           const washingMachineId = this.$route.query.machineID
    //           console.log('washingMachineId', washingMachineId)
    //           // const redirectUri = `https://payment-aa635.web.app/modeView?machineID=${washingMachineId}&userId=${this.UserID}`
    //           // this.$router.push({ path: `/modeView?machineID=${washingMachineId}&userId=${idToken}` })
    //           const redirectUri = `https://payment-aa635.web.app/modeView?machineID=${washingMachineId}&userId=${this.userId}`
    //           this.$router.push({ path: '/modeView', query: { machineID: washingMachineId, userId: this.userId } })
    //           // await liff.login({ redirectUri: 'https://payment-aa635.web.app/modeView' })
    //         }
    //         // else {
    //         //   const redirectUri = 'https://payment-aa635.web.app/'
    //         //   liff.login({ redirectUri })
    //         // }
    //       })
    //   } catch (error) {
    //     console.error('LINE login error:', error)
    //   }
    // },
    async initializeLIFF () {
      try {
        await liff.init({ liffId: '2000943917-2wg94M79' })

        if (liff.isLoggedIn()) {
          await this.getUserProfile()// Wait for user profile to be fetched and set UserID
          console.log('UserID after getUserProfile:', this.userId) // Log to check if UserID is set

          const washingMachineId = this.$route.query.machineID
          console.log('washingMachineId', washingMachineId)

          const redirectUri = `https://payment-aa635.web.app/modeView?machineID=${washingMachineId}&userId=${this.userId}`

          this.$router.push({ path: '/modeView', query: { machineID: washingMachineId, userId: this.userId } })
        }
      } catch (error) {
        console.error('LINE login error:', error)
      }
    },

    async getUserProfile () {
      try {
        const profile = await liff.getProfile()
        this.userProfile = profile
        this.userId = profile.userId
        console.log('UserId', this.userId)
        console.log(this.userProfile)
        return profile
      } catch (error) {
        console.log('Failed to get user profile:', error)
      }
    },
    async addFriend () {
      await liff.init({ liffId: '2000943917-2wg94M79' })
        .then(() => {
          const washingMachineId = this.$route.query.machineID
          const redirectUri = `https://payment-aa635.web.app/?machineID=${washingMachineId}`
          liff.login({ redirectUri })
        })
    },
    // async login () {
    //   try {
    //     if (!liff.isLoggedIn()) {
    //       await this.initializeLIFF()
    //       const washingMachineId = this.$route.query.machineID
    //       console.log('washingMachineId', washingMachineId)
    //       await liff.login({ redirectUri: 'https://payment-aa635.web.app/modeView' })
    //       await this.getUserProfile()
    //     }
    //   } catch (error) {
    //     console.error('LINE login error:', error)
    //   }
    // },
    // async getFriend () {
    //   try {
    //     await liff.init({ liffId: '2000943917-2wg94M79' })
    //     const isFriendship = await liff.getFriendship()
    //     console.log(isFriendship)

    //     if (isFriendship.friendFlag) {
    //       const idToken = liff.getAccessToken()
    //       const washingMachineId = this.$route.query.machineID
    //       this.$router.push({ path: '/modeView', query: { machineID: washingMachineId, userId: idToken } })
    //       console.log('friend')
    //     }
    //   } catch (error) {
    //     console.error('Error getting friend status:', error)
    //   }
    // },
    close () {
      const washingMachineId = this.$route.query.machineID
      this.$router.push({ path: '/modeView', query: { machineID: washingMachineId } })
    }
    // logOut () {
    //   liff.logout()
    //   window.location.reload()
    // }
  }
}
</script>
<style scoped>
h1 {
  color: black !important;
  text-align: center;
  font-weight: bold;
  padding: 0px;
  font-size: 32px;
}

hr.new1 {
  width: 50px;
}

.text {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 0px;
}

.close::before {
  content: '\2715';
}

.close {
  text-align: end;
  border: none;
  background-color: #FFFFFF;
}

.add-friend {
  padding: 12px;
  border: 1px solid #06c755;
  border-radius: 8px;
  background-color: #06c755;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  width: 200px;

}

.not-add {
  padding: 12px;
  border: 1px solid #06c755;
  border-radius: 8px;
  background-color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  margin: 5px;
  width: 200px;
}

.image {
  width: 100px;
  margin: 30px;
}

.custom-shape-divider-top-1696826698 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1696826698 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-top-1696826698 .shape-fill {
  fill: #FFFFFF;
}

.bg-bottom {
  position: relative;
  background-color: #4cc764;
  min-height: 30vh;
  margin-top: 80px;
}

.line-text {
  font-weight: bold;
  font-size: 20px;
  color: #4cc764;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-top-1696826698 svg {
    width: calc(155% + 1.3px);
    height: 118px;
  }

  .bg-bottom {
    min-height: 40vh;
  }
}
</style>
