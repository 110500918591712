<template>
    <!-- <button @click="showAlert">Hello world</button> -->
    {{ showAlert() }}
</template>
<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
export default {
  mounted () {
    this.showAlert()
  },
  methods: {
    showAlert () {
      Swal.fire({
        icon: 'error',
        title: 'ขออภัย',
        text: 'ขณะนี้เครื่องยังไม่พร้อมใช้งาน'
        // footer: '<a href="#">Why do I have this issue?</a>'
      })
    }
  }
}
</script>
<!-- <template>

<div class="card mx-auto" style="width: 18rem;">
  <div class="card-body">
    <div>
        <img src="@/assets/img/exclamation-mark.png" alt="exclamation" class="exclamation">
    </div>
    <h5 class="card-title mt-2">ขออภัย</h5>
    <h6 class="card-subtitle mb-2 mt-3 text-muted">ขณะนี้เครื่องยังไม่พร้อมใช้งาน</h6>
  </div>
</div>

</template>
<style scoped>
.exclamation{
    width: 24px;
}
.card{
    border: 1px solid red;
    background-color: rgb(254 226 226 );
}
</style> -->
