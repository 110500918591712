
<template>
  <div class="mb-3">
    <div class="bg">
      <div class="head">
        <div class="bg-logo">
          <img src="@/assets/img/washingmachine.png" alt="logo" class="logo">
        </div>
        <h1>JustWash</h1>
      </div>
      <div class="header px-3">
        <div>
          <p class="name-dasc"><span class="textname">{{ this.machineDetail.name_desc }}</span></p>
        </div>
        <img src="@/assets/img/washing-machineone.png" alt="image" class="image">
      </div>

      <!-- svg -->
      <div class="custom-shape-divider-bottom-1695112065">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"></path>
        </svg>
      </div>
    </div>

    <!-- status -->
    <div v-if="isMachineWorking">
      <div class="mt-5">
        <p class="working">เครื่องกำลังทำงาน...</p>
        <p class="timer">เหลือเวลา <span class="currentime">{{ isTimeworking }}</span> นาที</p>
      </div>
      <washingMachice />
    </div>
    <div v-else-if="isMachineComplete" class="mt-5">
      <machineComplete/>
    </div>
    <div v-else-if="isMachineError" class="mt-5">
      <machiceError/>
    </div>
    <div v-else-if='isgetDryer' class="px-3 mt-5">
      <p class="text mb-4">Select Mode</p>
      <div class="mode" :class="['box', { 'box-selected': this.modeIds.includes(selectedModeID) }]" @click="selectMode(this.modeIds)">
      <DryerPage :mode="this.modeIds" :remainingVend="currentMachineMode.remainingVend" :isSelect="this.modeIds.includes(selectedModeID) && !isLoading"/>
      <loading v-if="isLoading" />
    </div>
    <div class="buttonconfirme px-3" @click="sendDryerGenQR">
        <button type="button" class="btn submit">ยืนยัน</button>
      </div>
    </div>
    <div v-else class="px-3 mt-5">
      <p class="text mb-4">Select Mode</p>

      <!-- select mode -->
      <div class="mode" :class="['box', { 'box-selected': item.id === selectedModeID }] " v-for='(item, index) in allMode' :key='index'  @click="selectMode(item.id)">
        <box :mode="item" :remainingVend="currentMachineMode.remainingVend" :isSelect="item.id === selectedModeID && !isLoading"></box>
        <loading v-if="isLoading" />
      </div>

      <div class="buttonconfirme px-3" @click="sendMode">
        <button type="button" class="btn submit">ยืนยัน</button>
      </div>
    </div>
    </div>
    {{ userId }}
</template>
<script>
// import axios from 'axios'
import { getMachine, setCycle as setMachineMode } from '@/service/api'
import box from '@/components/box.vue'
import loading from '@/components/loading.vue'
import washingMachice from '@/components/washingMachice.vue'
import machineComplete from '@/components/machineComplete.vue'
import machiceError from '@/components/machiceError.vue'
import DryerPage from '@/components/dryer.vue'

// firebase
import { ref, onValue, getDatabase, push, update, child } from 'firebase/database'
import { app } from '@/service/firebase'

export default {
  components: { box, loading, washingMachice, machineComplete, machiceError, DryerPage },
  userId: {
    type: String,
    required: true
  },
  data () {
    return {
      isLoading: false,
      machineID: '',
      machineDetail: {
        modes: [],
        name: '',
        name_desc: '',
        node_number: '',
        spq_control_id: null,
        spq_wm_id: ''
      },
      allMode: [],
      selectedModeID: null,
      currentMachineMode: {
        remainingVend: null,
        selectedCycle: null,
        remainingTimeInMinute: null
      },
      modeIds: []

    }
  },
  created () {
    const userId = this.$route.query.userId
    console.log('userId in mode.vue:', userId)
  },
  mounted () {
    this.getMachineDetail()
    // this.getDryer()
  },
  computed: {
    // คำนวณเวลา
    isTimeworking () {
      const { remainingTimeInMinute } = this.currentMachineMode

      const hours = Math.floor(remainingTimeInMinute / 60)
      // const minutes = remainingTimeInMinute % 60

      return hours.toString().padStart(2, '0')
    },
    // statusMachine
    isMachineWorking () {
      const { controlStatus } = this.currentMachineMode
      return ['READY_TO_START', 'IN_USE'].includes(controlStatus)
    },
    isMachineComplete () {
      const { controlStatus } = this.currentMachineMode
      return ['COMPLETE'].includes(controlStatus)
    },
    isMachineError () {
      const { controlStatus } = this.currentMachineMode
      return ['UNAVAILABLE', 'ERROR', 'NETWORK_ERROR'].includes(controlStatus)
    },
    isgetDryer () {
      return ['M032', 'M033', 'M034'].some(modeId => this.modeIds.includes(modeId))
    }
  },
  methods: {
    async getMachineDetail () {
      try {
        this.modeIds = []
        const response = await getMachine(this.$route.query.machineID)
        console.log(this.machineID)
        console.log(response)
        if (response.data.status === true) {
          this.machineDetail = response.data.data
          this.allMode = this.machineDetail.modes
          // this.modeId = this.allMode.map(mode => mode.id)
          // console.log('modeId', this.modeId)
          this.modeIds = this.allMode.map(mode => mode.id)
          console.log('modeIds', this.modeIds)
          this.getCurrentMachineMode()
        } else {
          console.log('เกิดข้อผิดพลาด')
        }
      } catch (error) {
        console.log('API Error:', error)
      }
    },
    // get mode firebase
    getCurrentMachineMode () {
      const database = getDatabase()
      const currentMachineModeRef = ref(database, `/${this.machineDetail.spq_wm_id}`)

      onValue(currentMachineModeRef, (snapshot) => {
        const data = snapshot.val()
        this.currentMachineMode = data
        console.log('currentMachineMode', this.currentMachineMode.selectedCycle)
        this.convertTexttoMode(this.currentMachineMode.selectedCycle, 'setSelectedMode')
        this.isLoading = false
      }, (error) => {
        console.log('Firebase Error:', error)
      })
    },

    convertTexttoMode (valueToFind, command = '') {
      const database = getDatabase()
      const modeRef = ref(database, 'mode')
      onValue(modeRef, (snapshot) => {
        const data = snapshot.val()
        const mode = data[valueToFind]
        if (command === 'setSelectedMode') {
          this.selectedModeID = mode
        }
      })
    },
    async selectMode (mode) {
      if (mode === this.selectedModeID) {
        return
      }

      this.selectedModeID = mode
      console.log(this.selectedModeID)
      this.isLoading = true
      this.currentMachineMode = {
        remainingVend: null,
        selectedCycle: null
      }
      this.machineID = this.$route.query.washing_machine_id
      console.log('washing_machine_id:', this.$route.query.machineID)
      try {
        const machineModeData = await setMachineMode(this.$route.query.machineID, this.selectedModeID)
        this.currentMachineMode.remainingVend = machineModeData.remainingVend
      } catch (error) {
        console.error('Error setting machine mode:', error)
        this.isLoading = false
      }
    },
    // sendModetoQr
    sendMode () {
      if (this.selectedModeID === '') {
        alert('กรุณาเลือกโหมด')
        return
      }
      this.$router.push({ path: '/payment', query: { washingMachineId: this.$route.query.machineID, mode: this.selectedModeID, userId: this.$route.query.userId, price: this.currentMachineMode.remainingVend } })
    },
    // sendDryerGenQR
    sendDryerGenQR () {
      if (this.selectedModeID === '') {
        alert('กรุณาเลือกโหมด')
        return
      }
      this.$router.push({ path: '/payment', query: { washingMachineId: this.$route.query.machineID, mode: 'M034', userId: this.$route.query.userId, price: this.currentMachineMode.remainingVend } })
    }
    // selectMode
    // async selectMode (mode) {
    //   if (mode === this.selectedModeID) {
    //     return
    //   }
    //   this.selectedModeID = mode
    //   this.isLoading = true
    //   this.currentMachineMode = {
    //     remainingVend: null,
    //     selectedCycle: null
    //   }
    //   this.machineID = this.$route.query.washing_machine_id
    //   console.log('washing_machine_id:', this.$route.query.machineID)
    //   await setMachineMode(this.$route.query.machineID, this.selectedModeID)
    //   this.isLoading = false
    // }

  }

}
</script>
<style scoped>
@import "@/assets/css/mode-css.css";
</style>
