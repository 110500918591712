
import axios from 'axios'

export const getAuthorization = () => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    return { Authorization: 'Token ' + accessToken }
  } else {
    console.log('No token')
    return {}
  }
}

export const GenerateQR = async (washingMachineId, mode, userId, price) => {
  const url = 'https://api.justwash.asia/api/method/justwash.PartnerApi.GetPaymentQrCode'
  try {
    const response = await axios.post(
      url,
      {
        washing_machine_id: washingMachineId,
        payment_id: 'Promptpay',
        mode: mode,
        userId: userId,
        price: price
      }
    )
    console.log(response.data)
    return {
      data: response?.data
    }
  } catch (error) {
    console.log('cannot genarete QR code')
    return {
      error
    }
  }
}
export const getMachine = async (machineID) => {
  const url = 'https://api-cloudpay.justwash.asia/apiQrOnCloud/WashingMachine'
  try {
    const response = await axios.get(
      url,
      {
        params: {
          id: machineID
        }
      }
    )
    console.log(response.data)
    return {
      data: response?.data
    }
  } catch (error) {
    console.log(error, 'cannot get getMachine')
    return {
      error
    }
  }
}

export const setCycle = async (washingId, mode) => {
  const url = 'https://spq-insights-cloudpay.justwash.asia/setCycle'
  try {
    const response = await axios.post(url, {
      washing_machine_id: washingId,
      mode: mode
    })
    console.log('API Response:', response.data)
    return {
      data: response?.data
    }
  } catch (error) {
    console.error('Error in setCycle:', error)
    return {
      error: error.message || 'Unknown error occurred'
    }
  }
}
