<template>
    <div :class="['box dryer-content'] ">
        <p class="mode-text">อบผ้า</p>
        <p v-if="isSelect && remainingVend" class="price">ราคา {{ remainingVend  }}  บาท</p>
    </div>
</template>
<script>
export default {
  name: 'DryerPage',
  props: {
    mode: {
      type: Object,
      required: true
    },
    remainingVend: {
      type: Number,
      required: true
    },
    isSelect: {
      type: Boolean,
      required: false
    },
    sendMode: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped>
.dryer-content{
display: contents;
}
</style>
