
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyD2jELrCFdbx72K_pwBfSMlZhVJk8cOPNU',
  authDomain: 'sq-realtime.firebaseapp.com',
  databaseURL: 'https://sq-realtime-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'sq-realtime',
  storageBucket: 'sq-realtime.appspot.com',
  messagingSenderId: '87102738488',
  appId: '1:87102738488:web:84407f03908fb1833e56cd',
  measurementId: 'G-2Z2S38TTED'
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
export { app, analytics }
