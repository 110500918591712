import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import modeView from './views/modeView.vue'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import VConsole from 'vconsole'

const vConsole = new VConsole()

const app = createApp(App)

app.config.productionTip = false

// Mount the main Vue instance with the router and store
app.use(store).use(router).use(VueSweetalert2).mount('#app')

// Mount the modeView component separately
createApp(modeView).mount('#liff-demo')

createApp(App).use(store).use(router).mount('#app')
