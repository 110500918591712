<template>
    <div :class="['box'] ">
        <p class="mode-text">{{ mode.mode_desc }}</p>
        <p v-if="isSelect && remainingVend" class="price">ราคา {{ remainingVend  }}  บาท</p>
    </div>
</template>
<script>
// import loading from '@/components/loading.vue'
export default {
  name: 'boxComponent',
  // components: { loading },
  props: {
    mode: {
      type: Object,
      required: true
    },
    remainingVend: {
      type: Number,
      required: true
    },
    isSelect: {
      type: Boolean,
      required: false
    }
    // isLoading: {
    //   type: Boolean,
    //   required: false
    // }
  },
  data () {
    // return {
    //   isLoading: false
    // }
  }
}
</script>
<style scoped>
/* .box-selected {
  background-color: green;
} */
.box{
  display: contents;
  justify-content: space-around;
  align-items: center;
}
@import "@/assets/css/mode-css.css";
</style>
