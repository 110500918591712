<!-- Dryer.vue -->

<template>
  <div class="dryer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <!-- SVG paths for your dryer icon -->
      <circle cx="12" cy="4" r="1" />
      <line x1="12" y1="4" x2="12" y2="16" />
      <path d="M7 8a5 5 0 0 1 10 0" />
      <path d="M2 20h20a5 5 0 0 1-10 0h0a5 5 0 0 1-10 0z" />
    </svg>
  </div>
</template>

<script>
</script>

<style scoped>
/* Your CSS styles for the dryer component */
.dryer {
  width: 24px;
  height: 24px;
  /* Add more styles as needed */
  background-color: lightgray; /* Add a background color for testing */
}
</style>
